import { useEffect, useState } from "react";
import { MenuItem, FormControl, Select, Box } from "@mui/material";

export type MicroColumnWithDropDownProps = {
  row: any;
  isEditing?: boolean;
};

export enum MicroUnitTypes {
  OVERRIDE = "new_value",
  PERCENTAGE = "percentage",
}

export const MicroUnitTypesTranslations = {
  [MicroUnitTypes.PERCENTAGE]: "% Change",
  [MicroUnitTypes.OVERRIDE]: "Override",
};

export function MicroColumnWithDropDown({ row, isEditing }: MicroColumnWithDropDownProps) {
  const [value, setValue] = useState("");
  useEffect(() => {
    if (row.original.type) {
      setValue(row.original.type);
    } else {
      setValue(MicroUnitTypes.PERCENTAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value) {
      const updatedOriginal = { ...row.original, type: value };
      row.original = updatedOriginal;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <Box alignSelf={"flex-start"}>
        <FormControl variant="standard" sx={{ ml: 2, pr: 2, width: "100%" }}>
          <Select
            labelId="column-with-dropdown-label"
            id="column-with-dropdown"
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            label=""
            disabled={!isEditing}
            sx={{
              "& .MuiSvgIcon-root": {
                fill: !isEditing ? "currentColor !important" : "",
              },
            }}
          >
            <MenuItem value={MicroUnitTypes.PERCENTAGE}>% Change</MenuItem>
            <MenuItem value={MicroUnitTypes.OVERRIDE}>Override</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
}
